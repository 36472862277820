import { base64ToBlob } from "base64-blob";
import moment from "moment";
import momentTz from "moment-timezone";

export const SignUpFormDataParser = async (obj: any) => {
  const formData = new FormData();
  formData.append("firstName", obj.firstName);
  formData.append("middleName", obj.middleName);
  formData.append("lastName", obj.lastName);
  formData.append("email", obj.email);
  formData.append("password", obj.password);
  formData.append("role", obj.role);
  
  if (obj.role === 'principal') {
    formData.append("country", obj.country);
    formData.append("province", obj.province);
    formData.append("city", obj.city);
    formData.append("governmentIssuedId", obj.governmentIssuedId);
    formData.append("validId", obj.validId.file);
  } else {
    formData.append("dateOfBirth", obj.dateOfBirth);
    formData.append("citizenship", obj.citizenship);
    formData.append("mobileNumber", obj.mobile);
    formData.append("phoneNumber", obj.phoneNumber);
    formData.append("residentialAddress", obj.residentialAddress);
    formData.append("regularPlaceOfBusiness", obj.placeOfBusiness);
    formData.append("appointmentNo", obj.appointmentNo);
    formData.append("rollNo", obj.rollNo);
    formData.append("ptrNo", obj.ptrNo);
    formData.append("ptrNoDate", obj.ptrNoDate);
    formData.append("ibpNo", obj.ibpNo);
    formData.append("ibpNoDate", obj.ibpNoDate);
    formData.append("tinNumber", obj.tinNo);
    formData.append("mcleComplianceNumber", obj.mcleNo);
    if (obj.professionalTaxReceipt.file) {
      formData.append("professionalTaxReceipt", obj.professionalTaxReceipt.file);
    }
    if (obj.goodMoralCertificate.file) {
      formData.append("certificationOfGoodMoralCharacter", obj.goodMoralCertificate.file);
    }
    if (obj.passportPhoto.file) {
      formData.append("passportSizePhoto", obj.passportPhoto.file);
    }
    if (obj.ibpId.file) {
      formData.append("ibpId", obj.ibpId.file);
    }
  }
  return formData;
};

export const SignedDocumentFormDataParser = async (obj: any) => {
  const formData = new FormData();

  formData.append("firstName", obj.firstName);
  formData.append("middleName", obj.middleName);
  formData.append("lastName", obj.lastName);
  formData.append("city", obj.city);
  formData.append("province", obj.province);
  formData.append("country", obj.country);
  formData.append("email", obj.email);
  formData.append("password", obj.password);
  formData.append("role", obj.role);
  if (obj.rollNo) {
    formData.append("rollNo", obj.rollNo);
  }
  if (obj.notaryCommNo) {
    formData.append("notaryCommNo", obj.notaryCommNo);
  }
  if (obj.validId.file) {
    formData.append("validId", obj.validId.file);
  }
  if (obj.ibpId.file) {
    formData.append("ibpId", obj.ibpId.file);
  }
  if (obj.notarialCommFileUrl.file) {
    formData.append("notarialCommFile", obj.notarialCommFileUrl.file);
  }

  return formData;
};

export const DocumentFormDataParser = async (obj: any, ipInfo: any) => {

  const formData = new FormData();
  const file = await base64ToBlob(obj.uri);
  formData.append("file", file, `${obj.name}.pdf`);
  formData.append("ip", ipInfo);
  formData.append("name", obj.name);
  formData.append("description", obj.description || "None");
  formData.append("type", 'file');
  formData.append("city", obj.city);
  formData.append("date", obj.date);
  formData.append("requestType", obj.requestType);
  formData.append("notarialAct", obj.notarialAct);
  formData.append("governmentIssuedIdType", obj.governmentIssuedIdType || '');
  if (obj.governmentIssuedId && obj.governmentIssuedId.file) {
    formData.append("governmentIssuedId", obj.governmentIssuedId.file, `${obj?.governmentIssuedId?.name}.pdf`);
  }

  formData.append("workflow[qrPosition]", obj.qrposition || "Bottom right");
  // formData.append("workflow[signOrder]", obj.signOrder || false);
  // formData.append("workflow[emailContent][subject]", obj.subject);
  // formData.append("workflow[emailContent][message]", obj.message || "");
  // formData.append("workflow[emailContent][expiration]", obj.expiration || null);

  obj.parties.forEach((party: any, index: any) => {
    if(party.roleType === "Principal" && obj.requestType === "Contactless"){
      formData.append(`workflow[parties][${index}][ip]`, ipInfo);
      formData.append(`workflow[parties][${index}][id]`, party.id || party.trackId);
      formData.append(`workflow[parties][${index}][trackId]`, party.trackId);
      formData.append(`workflow[parties][${index}][name]`, party.name);
      formData.append(`workflow[parties][${index}][email]`, party.email);
      formData.append(`workflow[parties][${index}][role]`, "SIGN");
      formData.append(`workflow[parties][${index}][status]`, "SIGNED");
      formData.append(`workflow[parties][${index}][loginType]`, obj.loginType);
      formData.append(`workflow[parties][${index}][signatureType]`, obj?.signature?.signatureType);
      formData.append(`workflow[parties][${index}][signatureId]`, obj?.signature?.signatureId);
      formData.append(`workflow[parties][${index}][timezone]`, momentTz.tz.guess());
      formData.append(`workflow[parties][${index}][viewed]`, `${+moment()}`);
      formData.append(`workflow[parties][${index}][executed]`, `${+moment()}`);
      formData.append(`workflow[parties][${index}][roleType]`, party.roleType);
    }else{
      formData.append(`workflow[parties][${index}][id]`, party.id || party.trackId);
      formData.append(`workflow[parties][${index}][trackId]`, party.trackId);
      formData.append(`workflow[parties][${index}][name]`, party.name);
      formData.append(`workflow[parties][${index}][email]`, party.email);
      formData.append(`workflow[parties][${index}][role]`, "SIGN");
      formData.append(`workflow[parties][${index}][roleType]`, party.roleType);
    }
  });

  obj.signatures.forEach((signature: any, x: number) => {
    signature.layerFields.forEach((layer: any, y: number) => {
      switch (layer.type){
      case "signature":
        formData.append(`workflow[signatures][${x}][${y}][id]`, layer.id);
        formData.append(`workflow[signatures][${x}][${y}][type]`, layer.type);
        formData.append(`workflow[signatures][${x}][${y}][trackId]`, layer.trackId);
        formData.append(`workflow[signatures][${x}][${y}][email]`, layer.email);
        formData.append(`workflow[signatures][${x}][${y}][config][id]`, layer.id);
        formData.append(`workflow[signatures][${x}][${y}][config][fill]`, layer.fill);
        formData.append(`workflow[signatures][${x}][${y}][config][x]`, layer.x);
        formData.append(`workflow[signatures][${x}][${y}][config][y]`, layer.y);
        formData.append(`workflow[signatures][${x}][${y}][config][width]`, layer.width);
        formData.append(`workflow[signatures][${x}][${y}][config][height]`, layer.height);
        formData.append(`workflow[signatures][${x}][${y}][config][rotation]`, layer.rotation || 0);
        formData.append(`workflow[signatures][${x}][${y}][config][stroke]`, layer.fill);
        formData.append(`workflow[signatures][${x}][${y}][config][strokeWidth]`, "1");
        break;
      case "signature-name":
        formData.append(`workflow[signatures][${x}][${y}][id]`, layer.id);
        formData.append(`workflow[signatures][${x}][${y}][type]`, layer.type);
        formData.append(`workflow[signatures][${x}][${y}][trackId]`, layer.trackId);
        formData.append(`workflow[signatures][${x}][${y}][email]`, layer.email);
        formData.append(`workflow[signatures][${x}][${y}][config][id]`, layer.id);
        formData.append(`workflow[signatures][${x}][${y}][config][fill]`, layer.fill);
        formData.append(`workflow[signatures][${x}][${y}][config][x]`, layer.x);
        formData.append(`workflow[signatures][${x}][${y}][config][y]`, layer.y);
        formData.append(`workflow[signatures][${x}][${y}][config][width]`, layer.width);
        formData.append(`workflow[signatures][${x}][${y}][config][height]`, layer.height);
        formData.append(`workflow[signatures][${x}][${y}][config][rotation]`, layer.rotation || 0);
        formData.append(`workflow[signatures][${x}][${y}][config][stroke]`, layer.fill);
        formData.append(`workflow[signatures][${x}][${y}][config][strokeWidth]`, "1");
        formData.append(`workflow[signatures][${x}][${y}][nameConfig][id]`, layer.id);
        formData.append(`workflow[signatures][${x}][${y}][nameConfig][x]`, layer.nameConfig.x);
        formData.append(`workflow[signatures][${x}][${y}][nameConfig][y]`, layer.nameConfig.y);
        formData.append(`workflow[signatures][${x}][${y}][nameConfig][text]`, layer.nameConfig.text);
        formData.append(`workflow[signatures][${x}][${y}][nameConfig][fontSize]`, layer.nameConfig.fontSize);
        formData.append(`workflow[signatures][${x}][${y}][nameConfig][width]`, layer.width);
        formData.append(`workflow[signatures][${x}][${y}][nameConfig][fontFamily]`, "DMSans");
        formData.append(`workflow[signatures][${x}][${y}][nameConfig][fill]`, "#000000");
        break;
      case "signature-date":
        formData.append(`workflow[signatures][${x}][${y}][id]`, layer.id);
        formData.append(`workflow[signatures][${x}][${y}][type]`, layer.type);
        formData.append(`workflow[signatures][${x}][${y}][trackId]`, layer.trackId);
        formData.append(`workflow[signatures][${x}][${y}][email]`, layer.email);
        formData.append(`workflow[signatures][${x}][${y}][config][id]`, layer.id);
        formData.append(`workflow[signatures][${x}][${y}][config][fill]`, layer.fill);
        formData.append(`workflow[signatures][${x}][${y}][config][x]`, layer.x);
        formData.append(`workflow[signatures][${x}][${y}][config][y]`, layer.y);
        formData.append(`workflow[signatures][${x}][${y}][config][width]`, layer.width);
        formData.append(`workflow[signatures][${x}][${y}][config][height]`, layer.height);
        formData.append(`workflow[signatures][${x}][${y}][config][rotation]`, layer.rotation || 0);
        formData.append(`workflow[signatures][${x}][${y}][config][stroke]`, layer.fill);
        formData.append(`workflow[signatures][${x}][${y}][config][strokeWidth]`, "1");
        formData.append(`workflow[signatures][${x}][${y}][dateConfig][id]`, layer.id);
        formData.append(`workflow[signatures][${x}][${y}][dateConfig][x]`, layer.dateConfig.x);
        formData.append(`workflow[signatures][${x}][${y}][dateConfig][y]`, layer.dateConfig.y);
        formData.append(`workflow[signatures][${x}][${y}][dateConfig][text]`, layer.dateConfig.text);
        formData.append(`workflow[signatures][${x}][${y}][dateConfig][fontSize]`, layer.dateConfig.fontSize);
        formData.append(`workflow[signatures][${x}][${y}][dateConfig][width]`, layer.width);
        formData.append(`workflow[signatures][${x}][${y}][dateConfig][fontFamily]`, "DMSans");
        formData.append(`workflow[signatures][${x}][${y}][dateConfig][fill]`, "#000000");
        break;
      
      case "full-name":
        formData.append(`workflow[signatures][${x}][${y}][id]`, layer.id);
        formData.append(`workflow[signatures][${x}][${y}][type]`, layer.type);
        formData.append(`workflow[signatures][${x}][${y}][trackId]`, layer.trackId);
        formData.append(`workflow[signatures][${x}][${y}][email]`, layer.email);
        formData.append(`workflow[signatures][${x}][${y}][config][id]`, layer.id);
        formData.append(`workflow[signatures][${x}][${y}][config][fill]`, layer.fill);
        formData.append(`workflow[signatures][${x}][${y}][config][x]`, layer.x);
        formData.append(`workflow[signatures][${x}][${y}][config][y]`, layer.y);
        formData.append(`workflow[signatures][${x}][${y}][config][width]`, layer.width);
        formData.append(`workflow[signatures][${x}][${y}][config][height]`, layer.height || 0);
        formData.append(`workflow[signatures][${x}][${y}][config][rotation]`, layer.rotation || 0);
        formData.append(`workflow[signatures][${x}][${y}][config][stroke]`, layer.fill);
        formData.append(`workflow[signatures][${x}][${y}][config][strokeWidth]`, "1");
        formData.append(`workflow[signatures][${x}][${y}][nameConfig][id]`, layer.id);
        formData.append(`workflow[signatures][${x}][${y}][nameConfig][x]`, layer.x);
        formData.append(`workflow[signatures][${x}][${y}][nameConfig][y]`, layer.y);
        formData.append(`workflow[signatures][${x}][${y}][nameConfig][text]`, layer.nameConfig.text);
        formData.append(`workflow[signatures][${x}][${y}][nameConfig][fontSize]`, layer.nameConfig.fontSize);
        formData.append(`workflow[signatures][${x}][${y}][nameConfig][width]`, layer.width);
        formData.append(`workflow[signatures][${x}][${y}][nameConfig][fontFamily]`, "DMSans");
        formData.append(`workflow[signatures][${x}][${y}][nameConfig][fill]`, "#000000");
        break;
      case "initials":
        formData.append(`workflow[signatures][${x}][${y}][id]`, layer.id);
        formData.append(`workflow[signatures][${x}][${y}][type]`, layer.type);
        formData.append(`workflow[signatures][${x}][${y}][trackId]`, layer.trackId);
        formData.append(`workflow[signatures][${x}][${y}][email]`, layer.email);
        formData.append(`workflow[signatures][${x}][${y}][config][id]`, layer.id);
        formData.append(`workflow[signatures][${x}][${y}][config][fill]`, layer.fill);
        formData.append(`workflow[signatures][${x}][${y}][config][x]`, layer.x);
        formData.append(`workflow[signatures][${x}][${y}][config][y]`, layer.y);
        formData.append(`workflow[signatures][${x}][${y}][config][width]`, layer.width);
        formData.append(`workflow[signatures][${x}][${y}][config][height]`, layer.height);
        formData.append(`workflow[signatures][${x}][${y}][config][rotation]`, layer.rotation || 0);
        formData.append(`workflow[signatures][${x}][${y}][config][stroke]`, layer.fill);
        formData.append(`workflow[signatures][${x}][${y}][config][strokeWidth]`, "1");
        break;
      
      case "date-time":
        formData.append(`workflow[signatures][${x}][${y}][id]`, layer.id);
        formData.append(`workflow[signatures][${x}][${y}][type]`, layer.type);
        formData.append(`workflow[signatures][${x}][${y}][trackId]`, layer.trackId);
        formData.append(`workflow[signatures][${x}][${y}][email]`, layer.email);
        formData.append(`workflow[signatures][${x}][${y}][config][id]`, layer.id);
        formData.append(`workflow[signatures][${x}][${y}][config][fill]`, layer.fill);
        formData.append(`workflow[signatures][${x}][${y}][config][x]`, layer.x);
        formData.append(`workflow[signatures][${x}][${y}][config][y]`, layer.y);
        formData.append(`workflow[signatures][${x}][${y}][config][width]`, layer.width);
        formData.append(`workflow[signatures][${x}][${y}][config][height]`, layer.height || 0);
        formData.append(`workflow[signatures][${x}][${y}][config][rotation]`, layer.rotation || 0);
        formData.append(`workflow[signatures][${x}][${y}][config][stroke]`, layer.fill);
        formData.append(`workflow[signatures][${x}][${y}][config][strokeWidth]`, "1");
        formData.append(`workflow[signatures][${x}][${y}][dateConfig][id]`, layer.id);
        formData.append(`workflow[signatures][${x}][${y}][dateConfig][text]`, layer.dateConfig.text);
        formData.append(`workflow[signatures][${x}][${y}][dateConfig][fontSize]`, layer.dateConfig.fontSize);
        formData.append(`workflow[signatures][${x}][${y}][dateConfig][fontFamily]`, "DMSans");
        formData.append(`workflow[signatures][${x}][${y}][dateConfig][fill]`, "#000000");
        formData.append(`workflow[signatures][${x}][${y}][dateConfig][x]`, layer.x);
        formData.append(`workflow[signatures][${x}][${y}][dateConfig][y]`, layer.y);
        formData.append(`workflow[signatures][${x}][${y}][dateConfig][width]`, layer.width);
        break;
      
      case "textbox":
        formData.append(`workflow[signatures][${x}][${y}][id]`, layer.id);
        formData.append(`workflow[signatures][${x}][${y}][type]`, layer.type);
        formData.append(`workflow[signatures][${x}][${y}][trackId]`, layer.trackId);
        formData.append(`workflow[signatures][${x}][${y}][email]`, layer.email);
        formData.append(`workflow[signatures][${x}][${y}][config][id]`, layer.id);
        formData.append(`workflow[signatures][${x}][${y}][config][fill]`, layer.fill);
        formData.append(`workflow[signatures][${x}][${y}][config][x]`, layer.x);
        formData.append(`workflow[signatures][${x}][${y}][config][y]`, layer.y);
        formData.append(`workflow[signatures][${x}][${y}][config][width]`, layer.width);
        formData.append(`workflow[signatures][${x}][${y}][config][height]`, layer.height || 0);
        formData.append(`workflow[signatures][${x}][${y}][config][rotation]`, layer.rotation || 0);
        formData.append(`workflow[signatures][${x}][${y}][config][stroke]`, layer.fill);
        formData.append(`workflow[signatures][${x}][${y}][config][strokeWidth]`, "1");
        formData.append(`workflow[signatures][${x}][${y}][inputConfig][x]`, layer.x);
        formData.append(`workflow[signatures][${x}][${y}][inputConfig][y]`, layer.y);
        formData.append(`workflow[signatures][${x}][${y}][inputConfig][text]`, layer.inputConfig.text);
        formData.append(`workflow[signatures][${x}][${y}][inputConfig][fontSize]`, layer.inputConfig.fontSize);
        formData.append(`workflow[signatures][${x}][${y}][inputConfig][width]`, layer.width);
        formData.append(`workflow[signatures][${x}][${y}][inputConfig][height]`, layer.height);
        break;
      case "checkbox":
        formData.append(`workflow[signatures][${x}][${y}][id]`, layer.id);
        formData.append(`workflow[signatures][${x}][${y}][type]`, layer.type);
        formData.append(`workflow[signatures][${x}][${y}][trackId]`, layer.trackId);
        formData.append(`workflow[signatures][${x}][${y}][email]`, layer.email);
        formData.append(`workflow[signatures][${x}][${y}][config][id]`, layer.id);
        formData.append(`workflow[signatures][${x}][${y}][config][fill]`, layer.fill);
        formData.append(`workflow[signatures][${x}][${y}][config][x]`, layer.x);
        formData.append(`workflow[signatures][${x}][${y}][config][y]`, layer.y);
        formData.append(`workflow[signatures][${x}][${y}][config][width]`, layer.width);
        formData.append(`workflow[signatures][${x}][${y}][config][height]`, layer.height || 0);
        formData.append(`workflow[signatures][${x}][${y}][config][rotation]`, layer.rotation || 0);
        formData.append(`workflow[signatures][${x}][${y}][config][stroke]`, layer.fill);
        formData.append(`workflow[signatures][${x}][${y}][config][strokeWidth]`, "1");
        formData.append(`workflow[signatures][${x}][${y}][checkConfig][x]`, layer.x);
        formData.append(`workflow[signatures][${x}][${y}][checkConfig][y]`, layer.y);
        formData.append(`workflow[signatures][${x}][${y}][checkConfig][width]`, layer.checkConfig.width);
        formData.append(`workflow[signatures][${x}][${y}][checkConfig][height]`, layer.checkConfig.height);
        formData.append(`workflow[signatures][${x}][${y}][checkConfig][marginRight]`, layer.checkConfig.marginRight);
        formData.append(`workflow[signatures][${x}][${y}][inputConfig][x]`, layer.x);
        formData.append(`workflow[signatures][${x}][${y}][inputConfig][y]`, layer.y);
        formData.append(`workflow[signatures][${x}][${y}][inputConfig][width]`, layer.width);
        formData.append(`workflow[signatures][${x}][${y}][inputConfig][height]`, layer.height);
        formData.append(`workflow[signatures][${x}][${y}][inputConfig][text]`, layer.inputConfig.text);
        formData.append(`workflow[signatures][${x}][${y}][inputConfig][fontSize]`, layer.inputConfig.fontSize);
        break;
      case "qrcode_sign":
        formData.append(`workflow[signatures][${x}][${y}][id]`, layer.id);
        formData.append(`workflow[signatures][${x}][${y}][type]`, layer.type);
        formData.append(`workflow[signatures][${x}][${y}][trackId]`, layer.trackId || "");
        formData.append(`workflow[signatures][${x}][${y}][email]`, layer.email || "");
        formData.append(`workflow[signatures][${x}][${y}][config][id]`, layer.id);
        formData.append(`workflow[signatures][${x}][${y}][config][x]`, String(layer.x));
        formData.append(`workflow[signatures][${x}][${y}][config][y]`, String(layer.y));
        formData.append(`workflow[signatures][${x}][${y}][config][fill]`, "black");
        formData.append(`workflow[signatures][${x}][${y}][config][stroke]`, "black");
        formData.append(`workflow[signatures][${x}][${y}][config][strokeWidth]`, "1");
        formData.append(`workflow[signatures][${x}][${y}][config][rotation]`, "0");
        formData.append(`workflow[signatures][${x}][${y}][config][width]`, String(layer.width));
        formData.append(`workflow[signatures][${x}][${y}][config][height]`, String(layer.height));
        break;
      }
    });
  });

  return formData;
};
