import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { Button01 } from '@src/components/button';
import { SignupContext } from './SignupProvider';
import { Text, View } from 'react-native';
import useStyles from './styles.css';

type EmailSentProps = {
  notary?: boolean;
};

const EmailSent = ({ notary }: EmailSentProps) => {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const { updateScreen } = React.useContext(SignupContext);

  const onBack = () => {
    updateScreen('');
    navigation.navigate('Signin');
  };

  const principalContent = () => {
    return (
      <View style={styles.signInCenter}>
        <Text style={styles.t_email_sent}>Email sent</Text>
        <Text style={styles.t_email_sent_notes}>
          Click the link that was sent to your email to complete the
          verification process. You will only be able to access the dashboard
          once you are verified.
        </Text>
      </View>
    );
  };

  const notaryContent = () => {
    return (
      <View style={styles.signInCenter}>
        <Text style={styles.t_email_sent_notes1}>
          Application Sent
        </Text>
        <Text style={[styles.t_email_sent_notes, styles.t_marginTopBottom,]}>
          Your application has been sent and is currently being processed.<br/>
          We will get back to you once it has been approved and verified.
        </Text>
      </View>
    );
  };

  return (
    <View style={styles.v_signup}>
      {notary ? notaryContent() : principalContent()}
      {/* {notary ? principalContent() : notaryContent()} */}
      <Button01
        // label={notaryContent() ? "Back to login" : "Done"}
        label={"Done"}
        style={styles.btn_backtologin}
        labelStyle={styles.lbl_signup}
        onPress={onBack}
      />
      { principalContent() ? <Text style={styles.t_email_sent_notes2}>
        Did not receive the email? Check your spam or junk folder
      </Text> : '' } 
    </View>
  );
};

export default EmailSent;
