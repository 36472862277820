import { RECAPTCHA_SITE_KEY } from "@env";
import colors from '@assets/colors';
import Icon from "@expo/vector-icons/MaterialIcons";
import { useNavigation } from '@react-navigation/native';
import { Button01 } from '@src/components/button';
import { TextInput02 } from '@src/components/textinput';
import { Tooltip01 } from '@src/components/tooltip';
import { useSignupService } from '@src/ducks/hooks';
import { SignupInput } from '@src/ducks/types';
import { useInputHelper } from '@src/utils/inputs-helper';
import _ from 'lodash';
import React, { useContext, useEffect, useState, useRef } from 'react';
import { Text, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import Validator from 'validator';
import { SignupContext } from './SignupProvider';
import useStyles from './styles.css';
import Terms from './terms';
import UploadMask from './upload-mask';
import Dropdown from '@src/components/dropdown/drop-down';
import { TextInput03 } from '@src/components/textinput';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import psgc from "@dropdowns/psgc";
import { ValidID } from '@src/utils/mockdata';
import ReCAPTCHA from 'react-google-recaptcha';

const initialInputState = {
  firstName: '',
  middleName: '',
  lastName: '',
  country: 'Philippines',
  province: {},
  city: '',
  governmentIssuedId: '',
  validId: {},
  email: '',
  password: '',
  confirmpassword: '',
  role: 'principal',
  // dateOfBirth: '',
  // citizenship: '',
  // phoneNumber: '',
  // mobile: '',
  // residentialAddress: '',
  // placeOfBusiness: '',
  // rollNo: '',
  // ibpNo: '',
  // mcleNo: '',
  // tinNo: '',
  // adminMatterNo: '',
  // ibpId: {},
  // professionalTaxReceipt: {},
  // passportPhoto: {},
  // goodMoralCertificate: {},
} as SignupInput;

const Principal = () => {
  const styles = useStyles();
  const navigation = useNavigation<any>();
  const [terms, setTerms] = useState<boolean>(false);
  const { updateScreen } = useContext(SignupContext);
  const { data, failed, isLoading, onSignup } = useSignupService();
  const { state, onDispatch } = useInputHelper(initialInputState);
  const [error, setError] = useState<SignupInput>({} as SignupInput);
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const recaptchaRef = useRef<ReCAPTCHA>()

  const onChangeText = (type: string) => (value: string) => {
    onDispatch(type)(value);
    setError({} as SignupInput);
  };

  const handleSubmit = () => {
    const newErr: any = {} as SignupInput;

    const requiredFields = [
      'firstName',
      // 'middleName',
      'lastName',
      'country',
      'province',
      'city',
      'governmentIssuedId',
      'email',
      'password',
      'confirmpassword',
      'validId'
    ];

    const clonedState = _.cloneDeep(state);

    clonedState.province = clonedState.province.name
    clonedState.city = clonedState.city.name

    requiredFields.forEach((field) => {
      if (_.isEmpty(clonedState[field])) {
        newErr[field] = 'This field is required';
      }
    });

    if (clonedState.email && !Validator.isEmail(clonedState.email)) {
      newErr.email = 'Invalid email format';
    }

    if (clonedState.password !== clonedState.confirmpassword) {
      newErr.confirmpassword = 'Passwords do not match';
    }

    // if (clonedState.password.length <= 5) {
    //   newErr.password = 'Password must be at least six characters';
    // }

    if (clonedState.password.length < 8 || 
      !/[A-Z]/.test(clonedState.password) || 
      !/[^A-Za-z0-9]/.test(clonedState.password) ||
      !/[0-9]/.test(clonedState.password)) {
        newErr.password =  "Password Requirements:\n" +
        "• Minimum of 8 characters\n" +
        "• At least one upper case letter\n" +
        "• At least one symbol\n" +
        "• At least one number";
    }

    if (!captchaToken && recaptchaRef.current) {
      recaptchaRef.current.execute();
    }

    // onSignup(state);
    setError(newErr);
    if (_.isEmpty(newErr) && captchaToken) {
      onSignup(clonedState);
    }
  };

  const handleCaptchaChange = (token: string | null) => {
    setCaptchaToken(token);
  };

  const handleCaptchaExpired = () => {
    setCaptchaToken(null);
  };

  const handlePasswordLength = () => {
    if (state.password.length < 8 || 
      !/[A-Z]/.test(state.password) || 
      !/[^A-Za-z0-9]/.test(state.password) ||
      !/[0-9]/.test(state.password)) {
      setError((prevErrors) => ({
        ...prevErrors,
        password: "Password Requirements:\n" +
        "• Minimum of 8 characters\n" +
        "• At least one upper case letter\n" +
        "• At least one symbol\n" +
        "• At least one number",
      }));
    }
  };

  useEffect(() => {
    if (!_.isEmpty(failed)) {
      setError({ message: failed.message } as SignupInput);
    }
  }, [failed]);

  useEffect(() => {
    if (!_.isEmpty(data)) {
      updateScreen('emailsent-principal');
    }
  }, [data]);

  return (
    <View style={styles.v_signup}>
      <ScrollView>
        <View style={styles.v_height} />
        {/* <BackButton /> */}
        <View style={styles.signInCenter}>
          <Text style={styles.txtNote1}>Create an account</Text>
          <Text style={styles.t_publicnotary}>
            You are signing up as principal
          </Text>
          <Text style={styles.txtNote2}>
            Enter your name as it appears on your government-issued ID
          </Text>
        </View>
        <View>
          <View style={styles.v_wrap_row}>
            <TextInput02
              label="First Name"
              value={state.firstName}
              error={error.firstName}
              style={styles.v_flex1}
              placeholder="Your First Name"
              onChangeText={onChangeText('firstName')}
            />
            <View style={styles.v_divider} />
            <TextInput02
              label="Middle Name (optional)"
              style={styles.v_flex1}
              value={state.middleName}
              error={error.middleName}
              placeholder="Your Middle Name"
              onChangeText={onChangeText('middleName')}
            />
            <View style={styles.v_divider} />
            <TextInput02
              label="Last Name"
              style={styles.v_flex1}
              value={state.lastName}
              error={error.lastName}
              placeholder="Your Last Name"
              onChangeText={onChangeText('lastName')}
            />
          </View>

          <View style={styles.v_wrap_row}>
            <TextInput02
              label="Country"
              value={state.country}
              error={error.country}
              style={styles.v_flex1}
              placeholder="Philippines"
              onChangeText={onChangeText('country')}
              editable={false}
            />
          </View>

          <View style={styles.v_wrap_row}>
            <Dropdown
              options={psgc.getAllProvinces()}
              style={styles.v_flex1}
              value={state.province.name}
              onSelected={onChangeText('province')}
              renderItem={(props: any) => (
                <Text style={styles.t_menu_item}>{props.item}</Text>
              )}
              renderBase={(props: any) => {
                return (
                  <TextInput03
                    value={props.value}
                    label="Province"
                    placeholder="Select Province"
                    onPress={props.onPress}
                    error={error.province}
                    renderIcon={() => {
                      return (
                        <MaterialIcons
                          name={'expand-more'}
                          color={colors.black6}
                          size={24}
                        />
                      );
                    }}
                  />
                );
              }}
            />
            <View style={styles.v_divider} />
            <Dropdown
              options={psgc.getMunicipalitiesByProvince(state?.province?.prv_code || "")}
              style={styles.v_flex1}
              value={state.city}
              onSelected={onChangeText('city')}
              renderItem={(props: any) => (
                <Text style={styles.t_menu_item}>{props.item}</Text>
              )}
              renderBase={(props: any) => {
                return (
                  <TextInput03
                    value={props.value?.name}
                    label="City"
                    placeholder="Select City"
                    onPress={props.onPress}
                    error={error.city}
                    renderIcon={() => {
                      return (
                        <MaterialIcons
                          name={'expand-more'}
                          color={colors.black6}
                          size={24}
                        />
                      );
                    }}
                  />
                );
              }}
            />
          </View>
          <View style={styles.v_wrap_row}>
            <Dropdown
              options={ValidID}
              style={{width: '49%'}}
              value={state.governmentIssuedId}
              onSelected={onDispatch('governmentIssuedId')}
              renderItem={(props: any) => (
                <Text style={styles.t_menu_item}>{props.item}</Text>
              )}
              renderBase={(props: any) => {
                return (
                  <TextInput03
                    value={props.value}
                    placeholder="Government Issued ID"
                    label="Government Issued ID"
                    onPress={props.onPress}
                    error={error.governmentIssuedId}
                    renderIcon={() => {
                      return (
                        <MaterialIcons
                          name={'expand-more'}
                          color={colors.black6}
                          size={24}
                        />
                      );
                    }}
                  />
                );
              }}
            />
            <View style={styles.v_divider} />
            <View style={{height: 110}} />
            <UploadMask
              label="Upload Valid ID"
              style={styles.v_flex1}
              value={state.validId.name}
              error={Boolean(error.validId)}
              onSource={onDispatch('validId')}
            />
            <Tooltip01
              style={{top: "-70%",}}
              label={`We accept government-issued \n ID cards like Driver's License, \n SSS ID, PhilHealth ID, UMID \n Company ID, etc.`}
            >
              <Icon name="help-outline" size={16} color={colors.gray7} style={{marginTop: 46, paddingLeft: 10, opacity: 1}} />
            </Tooltip01>
          </View>

          <View style={styles.v_wrap_row}>
            <TextInput02
              label="Email"
              value={state.email}
              error={error.email}
              style={styles.v_flex1}
              placeholder="Enter your email"
              onChangeText={onChangeText('email')}
            />
          </View>

          <View style={styles.v_wrap_row}>
            <TextInput02
              hasPassword
              label="Password"
              style={styles.v_flex1}
              value={state.password}
              error={error.password}
              placeholder="⬤⬤⬤⬤⬤⬤⬤⬤⬤⬤"
              onBlur={handlePasswordLength}
              onChangeText={onChangeText('password')}
            />
            <View style={styles.v_divider} />
            <TextInput02
              hasPassword
              style={styles.v_flex1}
              label="Confirm Password"
              value={state.confirmpassword}
              error={error.confirmpassword}
              placeholder="⬤⬤⬤⬤⬤⬤⬤⬤⬤⬤"
              onChangeText={onChangeText('confirmpassword')}
            />
          </View>

          <Terms isCheck={terms} onCheck={() => setTerms(!terms)} />
          <Button01
            label="Sign Up"
            color={colors.white}
            isLoading={isLoading}
            onPress={handleSubmit}
            style={styles.btn_signup}
            labelStyle={styles.lbl_signup}
            disabled={!terms}
          />
          {!_.isEmpty(error.message) && (
            <Text style={styles.t_error_message}>{error.message}</Text>
          )}
          <Text style={styles.t_signup}>
            Already have an account?
            <Text
              onPress={() => navigation.navigate('Signin')}
              style={styles.t_terms}
            >
              {' '}
              Login
            </Text>
          </Text>
        </View>
        <View style={styles.v_height} />
      </ScrollView>
      <ReCAPTCHA
        sitekey={RECAPTCHA_SITE_KEY}
        ref={recaptchaRef}
        size="invisible"
        onChange={handleCaptchaChange}
        onExpired={handleCaptchaExpired}
      />
    </View>
  );
};

export default Principal;
