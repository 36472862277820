import React, { useRef, useCallback } from 'react';
import {
  View,
  Text,
  Modal,
  StyleSheet,
  FlatList,
  TouchableOpacity,
  useWindowDimensions,
  TouchableWithoutFeedback,
} from 'react-native';
import { colors } from 'theme';

const ITEM_HEIGHT = 50;
export interface MeasureValue {
  x: number;
  y: number;
  width: number;
  height: number;
}

interface MenuDropdownProps {
  visible: boolean;
  accessible?: boolean;
  measures: MeasureValue;
  value: any;
  options: any;
  onClose: () => void;
  renderItem?: any;
  onSelected: (item: any) => void;
}

const MenuDropdown = (props: MenuDropdownProps) => {
  const flatlist = useRef(null);
  const { height: HEIGHT } = useWindowDimensions();
  const stylePosition = React.useMemo(() => {
    const { x, y, width } = props.measures;
    const base_height =
      props.options.length > 6
        ? ITEM_HEIGHT * 6
        : props.options.length * ITEM_HEIGHT;
    const pos_upward = y + base_height;
    const isGreaterThanHeight = pos_upward > HEIGHT;
    const positionStyle = {
      height: base_height,
      width: width,
      left: x,
      top: isGreaterThanHeight ? y - base_height : y,
    };

    return positionStyle;
  }, [props.measures, HEIGHT]);

  const RenderItem = useCallback(
    ({ item, index }: any) => {
      let newRow = typeof item === 'string' ? item : item?.name ?? '';

      return (
        <TouchableOpacity
          key={`${index}`}
          onPress={() => {
            props.onSelected(item);
            props.onClose();
          }}
          style={[
            styles.v_item,
            newRow === props.value && styles.v_item_active,
          ]}
        >
          {typeof props.renderItem === 'function' ? (
            props.renderItem({ item: newRow })
          ) : (
            <>
              <View style={[styles.v_circle, { backgroundColor: item.fill }]} />
              <Text style={styles.t_item}>{newRow}</Text>
            </>
          )}
        </TouchableOpacity>
      );
    },
    [props.value, props.onSelected]
  );

  return (
    <Modal
      transparent
      visible={props.visible}
      animationType={'none'}
      onRequestClose={props.onClose}
      supportedOrientations={[
        'portrait',
        'portrait-upside-down',
        'landscape',
        'landscape-left',
        'landscape-right',
      ]}
    >
      <TouchableWithoutFeedback
        accessible={props.accessible}
        onPress={props.onClose}
      >
        <View style={styles.modal}>
          <View style={[styles.dropdown, stylePosition]}>
            <FlatList
              ref={flatlist}
              data={props.options}
              getItemLayout={(item, index) => ({
                length: ITEM_HEIGHT,
                offset: ITEM_HEIGHT * index,
                index,
              })}
              // eslint-disable-next-line react/no-unstable-nested-components
              ItemSeparatorComponent={() => (
                <View style={styles.itemSeparator} />
              )}
              keyExtractor={(_, index) => `${index}`}
              renderItem={RenderItem}
            />
          </View>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  );
};

const styles = StyleSheet.create({
  modal: { flexGrow: 1 },
  dropdown: {
    width: 400,
    position: 'absolute',
    borderRadius: 4,
    backgroundColor: 'white',
    justifyContent: 'center',
    shadowColor: 'gray',
    shadowOffset: { width: 1, height: 1 },
    shadowOpacity: 0.6,
    shadowRadius: 10,
  },
  itemSeparator: {
    height: 1,
    marginHorizontal: 10,
    backgroundColor: colors.offwhite,
  },
  v_item: {
    minHeight: ITEM_HEIGHT,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 20,
  },

  v_item_active: { backgroundColor: colors.offwhite },
  v_circle: {
    width: 24,
    height: 24,
    borderRadius: 24 / 2,
    backgroundColor: colors.black,
  },
  t_item: {
    flex: 1,
    fontSize: 15,
    lineHeight: 20,
    marginLeft: 20,
    color: colors.black,
    fontFamily: 'DMSans',
  },
});

export default MenuDropdown;
