import Icon from "@expo/vector-icons/MaterialIcons";
import Button from "@src/components/button/Button04";
import { RequestContext } from '../../../RequestProvider';
import React, { useContext } from "react";
import { Text } from "react-native";
import useStyles from "./styles.css";
import { useAppSelector } from "@src/ducks/ducksHook";
import { selectedAuthRole } from '@src/ducks/slices/auth.slice';
import { DrawerContext } from "../../../DrawerProvider";

const getButtonLabel = (title: string) => {
  switch(title){
  case "Signatures":
    return "Upload Signature";
  default:
    return "Create a New Request";
  }
};

const CreateButton = () => {
  const styles = useStyles();
  const role = useAppSelector(selectedAuthRole);
  const { onCreateRequestPress } = useContext(RequestContext);
  const { activeTab, onSetActiveSecondScreen, onAddTeamButton } = React.useContext(DrawerContext);
  const ButtonLabel = React.useMemo(() => getButtonLabel(activeTab), [activeTab]);
  
  const onPress = React.useCallback(() => {
    switch(activeTab){
    case "Signatures":
      onSetActiveSecondScreen("signature-upload");
      break;
    default:
      onCreateRequestPress()
      break;
    }
  },[activeTab]);
  
  if (role !== 'notary' || activeTab == 'Signatures') {
    return (
      <Button
        onPress={onPress}
        style={styles.btn_createdocument} >
        <>
          <Text style={styles.btn_label}>{ButtonLabel}</Text>
          <Icon name="add" size={15} color="white" />
        </>
      </Button>
    );
  } else {
    return null;
  }
};

export default CreateButton;
