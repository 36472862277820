import React, { useCallback, useEffect, useState } from 'react';
import { useUserService, useAuthService } from './ducks/hooks';
import ErrorMessage from './components/modals/error-sideway';
import SessionExpired from './components/session-expired';
import SuccessTop from './components/modals/success-top';
import { useAppSelector } from './ducks/ducksHook';
import { selectUserFailed } from './ducks/slices/user.slice';

interface AuthProviderProps {
  children: React.ReactElement;
}

interface ContextValue {
  onErrorMessage: (value: string) => void;
  onSuccessMessage: (value: string) => void;
}

export const AuthContext = React.createContext<ContextValue>(
  {} as ContextValue
);

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [faceIDErrorCount, setFaceIDErrorCount] = useState<number>(0);
  const [error, setError] = useState<string | null>(null);
  const { isLoggedIn, onRefreshToken } = useAuthService();
  const { resetError, fetUserDetails } = useUserService();
  const isSessionExpired = useAppSelector(selectUserFailed);
  const [success, setSuccess] = useState<string | null>(null);

  const onErrorMessage = useCallback(setError, [error]);
  const onSuccessMessage = useCallback(setSuccess, [success]);

  useEffect(() => {
    if (isLoggedIn) {
      fetUserDetails();
      onRefreshToken();
    }
  }, [isLoggedIn]);

  return (
    <AuthContext.Provider value={{ onErrorMessage, onSuccessMessage, faceIDErrorCount, setFaceIDErrorCount, isLoggedIn }}>
      {children}
      <SessionExpired visible={isSessionExpired} onPress={resetError} />
      <ErrorMessage
        message={error}
        visible={error !== null}
        onClose={() => setError(null)}
      />
      <SuccessTop
        message={success}
        visible={success !== null}
        onClose={() => setSuccess(null)}
      />
    </AuthContext.Provider>
  );
};

export default AuthProvider;
